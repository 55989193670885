/* Footer.css */

.footer {
    background-color: #add8e6; /* Light blue background color */
    padding: 20px;
    text-align: center;
  }
  
  .footer p {
    margin: 0;
    font-size: 1.5rem;
    color: #333;
  }
  
  .button-container {
    display: flex;
    justify-content: center;
    gap: 10px;
    margin-top: 10px;
  }
  
  .icon-button {
    background: none;
    border: none;
    cursor: pointer;
    padding: 10px;
  }
  
  .icon {
    width: 64px;
    height: 64px;
    fill: black;
    transition: fill 0.3s;
  }
  
  .icon-button:hover .icon {
    fill: white;
  }
  