/* Base Styles */
body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.wrapper {
  position: relative;
  background-color: #add8e6;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}

.emoji {
  position: absolute;
  font-size: 2rem;
}

.image {
  width: 90%;
  max-width: 800px;
  height: auto;
  border: 5px solid white;
  background-color: white;
  padding: 5px;
  margin: 10px auto;
  display: block;
}

.centered-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  padding: 0 20px;
}

.token-info {
  font-size: 2rem;
}

.button-container {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-bottom: 10px;
  flex-wrap: wrap;
}

.icon-button {
  background: none;
  border: none;
  cursor: pointer;
  padding: 10px;
}

.icon {
  width: 64px;
  height: 64px;
  fill: black;
  transition: fill 1s;
}

.icon-button:hover .icon {
  fill: white;
}

/* Medium Screens */
@media (min-width: 600px) {
  .image {
    width: 80%;
    max-width: 400px;
  }

  .token-info {
    font-size: 1.8rem;
  }

  .icon {
    width: 48px;
    height: 48px;
  }
}

/* Large Screens */
@media (min-width: 768px) {
  .image {
    width: 70%;
    max-width: 350px;
  }

  .token-info {
    font-size: 1.6rem;
  }

  .icon {
    width: 40px;
    height: 40px;
  }
}

/* Extra Large Screens */
@media (min-width: 1024px) {
  .image {
    width: 80%;
    max-width: 500px;
  }

  .token-info {
    font-size: 1.4rem;
  }

  .icon {
    width: 32px;
    height: 32px;
  }
}
