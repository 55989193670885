/* ScrollingImageGallery.css */

.scrolling-gallery {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    overflow: hidden;
    background-color: #add8e6; /* Light blue background color */
  }
  
  .scroll-row {
    display: flex;
    align-items: center;
    white-space: nowrap;
    overflow: hidden;
  }
  
  .scroll-image {
    width: 200px; /* Adjust the size as needed */
    height: auto;
    margin: 10px 10px;
    border: 2px solid beige; /* Add beige outline */
  }
  
  @keyframes scroll-left {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(-50%);
    }
  }
  
  @keyframes scroll-right {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(50%);
    }
  }
  
  .top-row {
    animation: scroll-left 60s linear infinite;
  }
  
  .middle-row {
    animation: scroll-right 30s linear infinite;
  }
  
  .bottom-row {
    animation: scroll-left 40s linear infinite;
  }
  