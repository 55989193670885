/* TikTokSection.css */

.tiktok-background {
    background-color: #ffeb99; /* Complementary yellowish color to #add8e6 */
    width: 100%;
    padding: 20px 0; /* Top and bottom padding */
  }
  
  .tiktok-section {
    margin: 0 auto;
    width: 90%;
    max-width: 1000px;
    text-align: center;
  }
  
  .tiktok-header {
    font-family: 'Comic Sans MS', 'Comic Sans', cursive;
    font-size: 3rem;
    font-weight: bold;
    color: #333;
    margin-bottom: 20px;
  }
  
  /* Adjust font size for smaller screens */
  @media (max-width: 768px) {
    .tiktok-header {
      font-size: 2rem;
    }
  }
  
  .tiktok-container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex-wrap: nowrap; /* Prevent items from wrapping */
  }
  
  /* Ensure the TikTok embed scales well */
  .tiktok-embed {
    background: #fff;
    border-radius: 10px;
    padding: 10px;
    flex: 1;
    min-width: 300px; /* Ensure a minimum width for the embed */
    max-width: 600px; /* Ensure a maximum width for the embed */
  }
  
  /* Adjust padding and margin for smaller screens */
  @media (max-width: 768px) {
    .tiktok-embed {
      padding: 5px;
    }
  }
  
  .tiktok-sticker {
    width: 64px;
    height: 64px;
    flex-shrink: 0; /* Prevent the stickers from shrinking */
  }
  
  /* Adjust layout for mobile devices */
  @media (max-width: 768px) {
    .tiktok-container {
      flex-direction: column;
    }
  
    .tiktok-sticker {
      display: block;
      margin: 10px auto; /* Center the stickers horizontally */
    }
  }
  